import configuration from '@app/config'
import WS from './WS'

// const PREFIX = (Platform.OS === 'web' ? '' : configuration.SERVER_PREFIX) + '/services/v1'
const PREFIX = configuration.SERVER_PREFIX + '/services/v1'

const api = {
    formulaire: {
        addForm: (newForm: CreateModifyFormulaire) => new WS(`${PREFIX}/formulaire`).post(newForm).execute(),
        getFormulaire: (formId: string) => new WS(`${PREFIX}/formulaire/${formId}`).get().execute<Formulaire>(),
        editForm: (form: CreateModifyFormulaire, formId: string) =>
            new WS(`${PREFIX}/formulaire/${formId}`).put(form).execute(),
        deleteForm: (formId: string) => new WS(`${PREFIX}/formulaire/${formId}`).delete().execute(),
        getAllForms: () => new WS(`${PREFIX}/formulaire/list`).get().execute<Formulaires>(),
    },
    home: {
        indicators: () => new WS(`${PREFIX}/home`).get().execute<Home>(), // PASSAGE HORS LIGNE OK
    },
    login: {
        login: () => new WS(`${PREFIX}/login`).post({}).execute<UserLogin>(),
    },
    modele: {
        getModele: (modeleId: string) => new WS(`${PREFIX}/modele/${modeleId}`).get().execute<Modele>(),
        getAllModeles: () => new WS(`${PREFIX}/modele/list`).get().execute<Modeles>(),
    },
    graph: {
        getIcpAmounts: (params: IcpAmountParams) => new WS(`${PREFIX}/graph/icpAmount`).post(params).execute(),
    },
    ppee: {
        editRedacteur: (ppeeId: string, idRedacteur: Id) =>
            new WS(`${PREFIX}/ppee/${ppeeId}`).put(idRedacteur).execute<Icp>(),
        editAnnexes: (ppeeId: string, annexes: DocumentsIcp) =>
            new WS(`${PREFIX}/ppee/${ppeeId}/annexes`).put(annexes).execute<Icp>(),
        addFormulaire: (ppeeId: string, form: CreateModifyFormulaire) =>
            new WS(`${PREFIX}/ppee/${ppeeId}/formulaire`).post(form).execute(),
        addAvenant: (ppeeId: string, avenant: Icp) =>
            new WS(`${PREFIX}/ppee/${ppeeId}/avenant`).post(avenant).execute<Icp>(),
        uploadPpee: (ppee: UploadedPpee) => new WS(`${PREFIX}/ppee/televersement`).post(ppee).execute<Icp>(),
        renewPpee: (ppeeId: string) => new WS(`${PREFIX}/ppee/${ppeeId}/renew`).put({}).execute<{ id: string }>(),
        getAllSuivis: (ppeeId: string): Promise<SuiviVisites> =>
            new WS(`${PREFIX}/ppee/${ppeeId}/suiviVisites`).get().execute(),
        createSuivi: (ppeeId: string, suivi: CreateModifySuiviVisite) =>
            new WS(`${PREFIX}/ppee/${ppeeId}/suiviVisites`).post(suivi).execute(),
        editSuivi: (ppeeId: string, id: string, suivi: CreateModifySuiviVisite) =>
            new WS(`${PREFIX}/ppee/${ppeeId}/suiviVisites/${id}`).put(suivi).execute(),
        getSuivi: (ppeeId: string, id: string): Promise<CreateModifySuiviVisite> =>
            new WS(`${PREFIX}/ppee/${ppeeId}/suiviVisites/${id}/json`).get().execute(),
    },
    users: {
        getApprobateurList: () => new WS(`${PREFIX}/users/approbateur`).get().execute<UsersApprobateur>(),
        setDefaultApprobateur: (idApprobateur: string | undefined) =>
            new WS(`${PREFIX}/users/approbateur`).put({ idApprobateur: idApprobateur }).execute(),
        getRedacteurList: (): Promise<UsersRedacteur> => new WS(`${PREFIX}/users/redacteur`).get().execute(),
    },
    admin: {
        // ADMIN Family
        addRiskFamily: (newFamily: CreateFamily) => new WS(`${PREFIX}/admin/family`).post(newFamily).execute(),
        getRiskFamily: (riskFamilyId: string) =>
            new WS(`${PREFIX}/admin/family/${riskFamilyId}`).get().execute<AdminFamily>(),
        editRiskFamily: (family: AdminFamily, familyId: string) =>
            new WS(`${PREFIX}/admin/family/${familyId}`).put(family).execute(),
        deleteRiskFamily: (riskFamilyId: string) => new WS(`${PREFIX}/admin/family/${riskFamilyId}`).delete().execute(),
        getAllRiskFamilies: () => new WS(`${PREFIX}/admin/family/list`).get().execute<Families>(),

        // ADMIN Modele
        addModele: (newModele: CreateModele) => new WS(`${PREFIX}/admin/modele`).post(newModele).execute(),
        editModele: (modele: AdminModele, modeleId: string) =>
            new WS(`${PREFIX}/admin/modele/${modeleId}`).put(modele).execute(),
        archiveModele: (modeleId: string) => new WS(`${PREFIX}/admin/modele/${modeleId}/archive`).post().execute(),
        getAllModeles: () => new WS(`${PREFIX}/admin/modele/list`).get().execute<AdminModeles>(),

        // ADMIN Role
        getRoleList: () => new WS(`${PREFIX}/admin/role`).get().execute<RoleList>(),
        modifyRole: (role: ModifyRole) => new WS(`${PREFIX}/admin/role`).put(role).execute(),
        getAllRights: () => new WS(`${PREFIX}/admin/role/allRights`).get().execute<Rights>(),

        // ADMIN Perimeter
        addPerimeter: (perimeter: CreatePerimeter) => new WS(`${PREFIX}/admin/perimeter`).post(perimeter).execute(),
        editPerimeter: (perimeterId: string, libelle: Libelle) =>
            new WS(`${PREFIX}/admin/perimeter/${perimeterId}`).put(libelle).execute(),
        modifyPerimeterActivation: (id: string, active: boolean) =>
            new WS(`${PREFIX}/admin/perimeter/${id}/activate?active=${active}`).put({}).execute(),
        getPerimeterList: (id?: string) =>
            new WS(id ? `${PREFIX}/admin/perimeter/list?id=${id}` : `${PREFIX}/admin/perimeter/list`)
                .get()
                .execute<AdminPerimeterList>(),

        // ADMIN User
        addUser: (user: CreateModifyUser) => new WS(`${PREFIX}/admin/user`).post(user).execute(),
        editUser: (user: CreateModifyUser, id: string) => new WS(`${PREFIX}/admin/user/${id}`).put(user).execute(),
        modifyUserActivation: (id: string, active: boolean) =>
            new WS(`${PREFIX}/admin/user/${id}/activate?active=${active}`).put({}).execute(),
        getUserListByPerimeter: (id?: string) =>
            new WS(id ? `${PREFIX}/admin/user/list?id=${id}` : `${PREFIX}/admin/user/list`)
                .get()
                .execute<UserPerimeterList>(),
        getInactiveUsers: (): Promise<User[]> => new WS(`${PREFIX}/admin/user/inactive`).get().execute(),

        // ADMIN Entreprise
        getAllDatas: (): Promise<FullEntreprises> => new WS(`${PREFIX}/admin/entreprise/allData`).get().execute(),
        addCompany: (company: CreateEntreprise): Promise<Entreprise> =>
            new WS(`${PREFIX}/admin/entreprise`).post(company).execute(),
        editCompany: (company: AdminEntreprise): Promise<FullEntreprise> =>
            new WS(`${PREFIX}/admin/entreprise/${company.id}`).put(company).execute(),
        deleteCompany: (companyId: string) => new WS(`${PREFIX}/admin/entreprise/${companyId}`).delete().execute(),

        // ADMIN Agence
        addEntity: (entity: CreateAgence) => new WS(`${PREFIX}/admin/agence`).post(entity).execute(),
        editEntity: (entity: Agence) => new WS(`${PREFIX}/admin/agence/${entity.id}`).put(entity).execute(),
        deleteEntity: (entityId: string) => new WS(`${PREFIX}/admin/agence/${entityId}`).delete().execute(),

        // ADMIN Documents
        getAllDocuments: () => new WS(`${PREFIX}/admin/document/list`).get().execute<AdminDocument[]>(),
        addDocument: (document: AddedDocument) => new WS(`${PREFIX}/admin/document`).post(document).execute(),
        editDocument: (document: AdminDocument, documentId: string) =>
            new WS(`${PREFIX}/admin/document/${documentId}`).put(document).execute(),
        deleteDocument: (documentId: string) => new WS(`${PREFIX}/admin/document/${documentId}`).delete().execute(),
    },
    company: {
        getAllAgenciesByCompany: (companyId: string): Promise<Agences> =>
            new WS(`${PREFIX}/entreprise/${companyId}/agences`).get().execute(),
        getAllContactsByCompany: (companyId: string): Promise<Contact[]> =>
            new WS(`${PREFIX}/entreprise/${companyId}/contacts`).get().execute(),
        getAllCompanies: (): Promise<Entreprises> => new WS(`${PREFIX}/entreprise/list`).get().execute(),
    },
    entity: {
        getAllContactsByEntity: (entityId: string): Promise<Contact[]> =>
            new WS(`${PREFIX}/agence/${entityId}/contacts`).get().execute(),
    },
    contact: {
        getContact: (contactId: string): Promise<Contact> => new WS(`${PREFIX}/contact/${contactId}`).get().execute(),
        addContact: (contact: CreateContact) => new WS(`${PREFIX}/contact`).post(contact).execute(),
        editContact: (contact: Contact) => new WS(`${PREFIX}/contact/${contact.id}`).put(contact).execute(),
        deleteContact: (contactId: string) => new WS(`${PREFIX}/admin/${contactId}`).delete().execute(),
        getAllContacts: (): Promise<Contacts> => new WS(`${PREFIX}/contact/list`).get().execute(),
        getCssctItems: (): Promise<ConfigurationEmails> => new WS(`${PREFIX}/icp/cssct`).get().execute(),
    },
    icp: {
        getIcp: (icpId: string): Promise<Icp> => new WS(`${PREFIX}/icp/${icpId}`).get().execute(),
        getIcpList: (): Promise<SimpleIcp[]> => new WS(`${PREFIX}/icp/simpleList?icp=true`).get().execute(),
        getPpeeList: (): Promise<SimpleIcp[]> => new WS(`${PREFIX}/icp/simpleList?icp=false`).get().execute(),
        getIcpFullList: (perimeterId: string): Promise<Icp[]> =>
            new WS(`${PREFIX}/icp/list?icp=true${perimeterId}`).get().execute(),
        getPpeeFullList: (perimeterId: string): Promise<Icp[]> =>
            new WS(`${PREFIX}/icp/list?icp=false${perimeterId}`).get().execute(),
        addIcp: (icp: Icp): Promise<Icp> => new WS(`${PREFIX}/icp`).post(icp).execute(),
        editIcp: (icp: Icp, icpId: string): Promise<Icp> => new WS(`${PREFIX}/icp/${icpId}`).put(icp).execute(),
        approbationIcp: (icpId: string): Promise<Icp> => new WS(`${PREFIX}/icp/${icpId}/toApprove`).post().execute(),
        sendToWorkInspection: (icpId: string): Promise<Icp> =>
            new WS(`${PREFIX}/icp/${icpId}/send/workInspection`).post().execute(),
        sendToCssct: (icpId: string, email: Email): Promise<Icp> =>
            new WS(`${PREFIX}/icp/${icpId}/send/cssct`).post(email).execute(),
        addPpee: (ppee: UploadedPpee): Promise<Icp> => new WS(`${PREFIX}/ppee/televersement`).post(ppee).execute(),
        sendInvitations: (icpId: string): Promise<Icp> => new WS(`${PREFIX}/icp/${icpId}/toRealise`).post().execute(),
        getUnknownEntities: (): Promise<UnknownEntity[]> => new WS(`${PREFIX}/icp/unknownEntities`).get().execute(),
        getUnknownMeasures: (): Promise<UnknownMeasure[]> => new WS(`${PREFIX}/icp/unknownMeasures`).get().execute(),
        deleteUnknownMeasures: (ids: string): Promise<UnknownMeasure[]> =>
            new WS(`${PREFIX}/icp/unknownMeasures/archives/${ids}`).delete().execute(),
        // Approbation ou non par l'approbateur
        approveIcp: (icpId: string): Promise<Icp> => new WS(`${PREFIX}/icp/${icpId}/approved`).post().execute(),
        refuseIcp: (icpId: string): Promise<Icp> => new WS(`${PREFIX}/icp/${icpId}/notApproved`).post().execute(),
        deleteIcp: (icpId: string) => new WS(`${PREFIX}/icp/${icpId}`).delete().execute(),
        getWiItems: (): Promise<ConfigurationEmails> => new WS(`${PREFIX}/icp/it`).get().execute(),
    },
    perimeter: {
        getPerimeterList: (): Promise<Perimeter[]> => new WS(`${PREFIX}/perimeter/list`).get().execute(),
    },
    family: {
        getFamilyList: (): Promise<Family[]> => new WS(`${PREFIX}/family/list`).get().execute(),
    },
    document: {
        getAllDocuments: (): Promise<Document[]> => new WS(`${PREFIX}/document/list`).get().execute(),
    },
    graphs: {
        getIcpAmounts: (params: IcpAmountParams): Promise<BarGraphData> =>
            new WS(`${PREFIX}/graph/icpAmount`).post(params).execute(),
    },
    intervention: {
        getInterventionObjects: (): Promise<string[]> => new WS(`${PREFIX}/intervention/objects`).get().execute(),
        getInterventionTypes: (): Promise<string[]> => new WS(`${PREFIX}/intervention/types`).get().execute(),
    },
    version: {
        get: (): Promise<MobileVersion> => new WS(`${PREFIX}/version`).get().execute(),
    },
    custom: {
        getInterface: (): Promise<Configuration> => new WS(`${PREFIX}/admin/configuration`).get().execute(),
        editInterface: (newConf: Configuration): Promise<Configuration> =>
            new WS(`${PREFIX}/admin/configuration`).put(newConf).execute(),
        getCssct: (): Promise<ConfigurationEmails> => new WS(`${PREFIX}/admin/configuration/cssct`).get().execute(),
        editCssct: (newConf: ConfigurationEmails): Promise<ConfigurationEmails> =>
            new WS(`${PREFIX}/admin/configuration/cssct`).put(newConf).execute(),
        getWi: (): Promise<ConfigurationEmails> => new WS(`${PREFIX}/admin/configuration/it`).get().execute(),
        editWi: (newConf: ConfigurationEmails): Promise<ConfigurationEmails> =>
            new WS(`${PREFIX}/admin/configuration/it`).put(newConf).execute(),
    },
}

export default api
